<template>
  <section
    ref="root"
    class="cards-slider wrapper mt-xxxxl mb-xxxxl"
    :class="`cards-slider--${type}`"
  >
    <div ref="wrapper">
      <h2 v-if="content.title" class="cards-slider__title mb-m">
        {{ content.title }}
      </h2>

      <Hoverable :label="'Drag'" :content="content.items">
        <Carousel
          ref="carousel"
          :items="content.items"
          :bullet-points="true"
          :prev-next="true"
        >
          <template #item="{ item }: { item: CardType }">
            <div class="js-carousel-item">
              <News v-if="type === 'news' && !isMobile" :content="item" />
              <Card v-else :content="item" />
            </div>
          </template>
          <template #prevButton="{ disabled }">
            <Action
              :content="{
                title: 'Previous',
                icon: 'arrow-long-left',
                tag: 'button',
                modifiers: ['no-label', 'transparent'],
              }"
              class="slider__button"
              :disabled="disabled"
              @click="carousel.prev()"
            />
          </template>
          <template #nextButton="{ disabled }">
            <Action
              :content="{
                title: 'Next',
                icon: 'arrow-long-right',
                tag: 'button',
                modifiers: ['no-label', 'transparent'],
              }"
              class="slider__button"
              :disabled="disabled"
              @click="carousel.next()"
            />
          </template>
        </Carousel>
      </Hoverable>
    </div>
  </section>
</template>

<script lang="ts">
import Hoverable from '@/components/ui/Hoverable.vue'

import type { Card as CardType } from '@/types'

export interface CarouselType {
  title?: string
  items: CardType[]
}
</script>

<script setup lang="ts">
// import { Observer } from 'gsap/Observer'

import { Carousel } from '@monofront/v-components'
import { gsap } from 'gsap'
import { onMounted, ref } from 'vue'

import Card from '@/components/cards/Default.vue'
import News from '@/components/cards/News.vue'
import { useAppear, useResponsive } from '@/utils/aware'

import type { PropType } from 'vue'

const isMobile = useResponsive('smaller', 'm')
const carousel = ref()
const wrapper = ref()

const props = defineProps({
  content: {
    type: Object as PropType<CarouselType>,
    required: true,
  },
  type: {
    type: String,
    default: 'defaults',
  },
})

const root = ref<HTMLElement>()

onMounted(() => {
  if (root.value) {
    const tl = gsap.timeline({})

    tl.set(wrapper.value, {
      opacity: 0,
    })
  }
})

useAppear(
  root,
  ({ isInViewport }) => {
    if (isInViewport) {
      const tl = gsap.timeline({
        delay: 0.1,
      })
      const cardsEl = gsap.utils.toArray('.js-carousel-item', root.value)

      if (props.type !== 'category' && props.content.items.length > 3) {
        tl.set(cardsEl, {
          x: '-500',
          opacity: 0,
        })
      }

      tl.to(wrapper.value, {
        duration: 1.5,
        opacity: 1,
        ease: 'Power1.easeOut',
      })

      if (props.type !== 'category' && props.content.items.length > 3) {
        tl.to(
          cardsEl,
          {
            duration: 3,
            x: 0,
            stagger: -0.05,
            opacity: 1,
            ease: 'power4.out',
          },
          '<0.2'
        )
        // tl.set(cardsEl, { clearProps: 'all' })
      }
    }
  },
  { rootMargin: '-25% 0px', once: true }
)
</script>

<style lang="scss" scoped>
.cards-slider {
  position: relative;
}

.cards-slider__title {
  @extend %fraunces-spec;

  // prettier-ignore
  @include fluid(font-size, (xxs: 3.6rem, xxl: 6rem));

  margin-top: 0;
}

.slider__button {
  padding-inline: 0;
}

:deep(.action__icon) {
  --icon-size: 4.8rem;

  transition: fill 0.2s ease-in-out;

  [class*='action--']:hover & {
    fill: $c-green-abr;
  }
}

:deep(.carousel) {
  --pagination-item-gap: 0.5rem;
  --slide-gap: 0.8rem;
  --c-pagination-item-background-active: #{$c-green-abr};
  --c-pagination-background-inactive: rgba(0, 0, 0, 10%);
  --c-scrollbar-foreground: transparent;
}

:deep(.carousel__scroller__list) {
  width: 100%;
}

:deep(.carousel__item) {
  width: calc(col(6) - 0.6rem);
  min-width: 26.5rem;

  .cards-slider--news & {
    width: calc(col(12) - 0.6rem);
  }
}

:deep(.carousel__pagination) {
  @include mq(m) {
    display: none;
  }
}

:deep(.carousel__controls) {
  position: absolute;
  top: 0;
  right: col(1);
  margin-top: 0;

  @include mq($until: m) {
    display: none;
  }

  @include mq(wrapper) {
    right: calc(168rem / #{$grid-cols-number} * 2);
  }
}
</style>
