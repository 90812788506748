<template>
  <component
    :is="is"
    ref="rootRef"
    @mouseenter="onMouseEnter"
    @focus="onFocus"
    @mouseleave="onMouseLeave"
    @blur="onMouseLeave"
  >
    <slot></slot>
  </component>
</template>

<script setup lang="ts">
import { type MaybeElement, type VueInstance } from '@vueuse/core'
import { ref } from 'vue'

import { useUiStore } from '@/stores/ui'

import type { Ref } from 'vue'

const props = defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
  is: {
    type: String,
    default: 'div',
  },
  label: {
    type: String,
    default: '',
  },
  revealDelay: {
    type: Number,
    default: 0,
  },
})

const ui = useUiStore()

const rootRef: Ref<MaybeElement | VueInstance> = ref(null)

const onMouseEnter = () => {
  if (props.content.length > 3) {
    ui.showCursorFollow = true
    ui.cursorFollowLabel = props.label || ''
  }
}

const onMouseLeave = () => {
  ui.showCursorFollow = false
}

const onFocus = () => {
  if (rootRef.value && props.content.length > 3) {
    const domEl =
      rootRef.value instanceof HTMLElement
        ? rootRef.value
        : (rootRef.value as VueInstance).$el
    const { top, left, width, height } = domEl.getBoundingClientRect()
    ui.cursorFollowX = left + (width / 2) // prettier-ignore
    ui.cursorFollowY = top + (height / 2) // prettier-ignore
  }
  onMouseEnter()
}

defineExpose({
  onMouseEnter,
  onMouseLeave,
  onFocus,
})
</script>
